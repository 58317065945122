.home{
    height: auto;
    width: 100%;
    margin-top: 7.5vh;
    display: flex;
    flex-direction: column;
    background-color: var(--grey-subtle);
}
.slide {
    height: 50vh;
    width: 100%;
    color: white;
    display: flex;
}
.leftSideOfSlide{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    align-items: self-start;
    row-gap: 10px;
}
.posterTitle p{
    font-size: xx-large;
    font-weight: 600;
}
.rightSideOfSlide{
    width: 40%;
    height:100%;
    margin-left: auto;
}
  
.rightSideOfSlide img {
    height: 80%;
    width: 40%;
    border: 3px solid greenyellow;
    margin-top: 6%;
}

.homeNav{
    height: 7vh;
    background-color: var(--cream-color);
    align-items: center;
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 7vh;
    z-index: 5;
    border-bottom: 2px solid red;
}
.homeNav a{
    color: black;
    text-decoration: none;
    margin-left: 5vw;
    font-size: larger;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    border: 2px solid var(--grey-color);
    font-weight: bold;
    box-shadow: 5px;
}
.homeNav a:hover{
    background-color: var(--golden-color);
}
.sectionHeading {
    margin-left: 5%;
    margin-right: 5%;
    background-color: var(--grey-subtle);
    padding-top: 9px;
}
.sectionHeading:nth-child(3) {
    background-color: var(--grey-color);
}
.sectionHeading:nth-child(7){
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 5%;
    background-color: var(--grey-color);
}

.nowShowing , .comingSoon{
    height: 140vh;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
}
.nowShowing{
    background-color: var(--grey-color);
}
.nowShowingContainers , .comingSoonContainers{
    height: 48%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5%;
    justify-content: center;
}
.nowShowingContainers img, .comingSoonContainers img{
    height: 66%;
    width: 85%;
}
.movieTitle p{
    font-weight: 600;
    text-transform: uppercase;
}
.movieRating{
    font-weight: 200;
}
.buttonColumn{
    max-height: 16%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.buttonColumn Button{
    margin-right: 20px;
    color: black;
    font-weight: 700;
    border: 1px solid black;
    max-height: 100%;
    flex-grow: 1;
    margin-bottom: 6px;
}
.buttonColumn Button:first-child{
    background-color: var(--golden-color);
}
.buttonColumn Button:hover{
    background-color: var(--grey-color);
}

.trailers{
    height: 40vh;
    width: 100%;
    background-color: var(--grey-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.trailerContainer{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.trailerVideo{
    height: 45%;
    width: 70%;
    display: flex;
    background-color: black;
}
.trailerVideo a{
    justify-self: center;
    align-self: center;
    margin-left: 30%;
}
.trailerVideo a img{
    height: 50%;
    width: 50%;
}
.trailerDetails{
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5px;
}

.trailerDetails ul li{
    display: inline;
    color: rgba(0, 0, 0, 0.7);
}
.trailerDetails ul li::before{
    content: "\25A0";
}

@media only screen and (max-width:1024px){
  .leftSideOfSlide{
    width: 40%;
  }  
  .rightSideOfSlide{
    width: 55%;
  }
  .rightSideOfSlide img{
    width: 50%;
    margin-left: 15%;
    min-height: 50%;
    max-height: 65%;
    margin-top: 15%;
  }
  .sectionHeading {
    margin-left: 0%;
    margin-right: 0%;
  }
  .nowShowing , .comingSoon{
    height: 110vh;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .nowShowingContainers , .comingSoonContainers{
    height: 50%;
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%;
    justify-content: center;
   }
   .nowShowingContainers img, .comingSoonContainers img{
    height: 60%;
    width: 90%;
   }


}

@media only screen and (max-width: 770px){

    .posterTitle p{
        font-size: var(--xx-large);
    }
    .posterDescription p{
        font-size: var(--medium);
    }
    .rightSideOfSlide img{
        height: 65%;
        width: 60%;
        min-width: none;
        margin-top: 15%;
    }
    .buttonColumn Button{
        margin-right: 20px;
        font-weight: 600;
        font-size: var(--medium);
    }
    .nowShowing , .comingSoon{
        height: 160vh;
        width: 100%;
    }
    .nowShowingContainers , .comingSoonContainers{
        max-height: 33%;
        width: 33%;
        min-height: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2%;
        justify-content: center;
    }
    .trailers{
        height: 80vh;
    }
    .trailerContainer{
        width: 50%;
        height: 50%;
    }
    .trailerVideo{
        height: 80%;
    }
}

@media only screen and (max-width: 520px){
    .slide{
        height: 40vh;
    }
    .rightSideOfSlide{
        display: none;
    }
    .leftSideOfSlide{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .posterTitle p{
        font-size: var(--x-large);
    }
    .posterDescription p{
        font-size: var(--small);
    }
    .buttonColumn{
        width: 90%;
    }
    .buttonColumn Button{
        font-size: var(--small);
        width: 80%;
    }
    .homeNav{
        display: None;
    }
    .nowShowing {
        height: 200vh;
        width: 100%;
    }
    .comingSoon{
        height: 175vh;
        width: 100%;
    }
    .nowShowingContainers , .comingSoonContainers{
        height: 24%;
        width: 50%;
    }

}