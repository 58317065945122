.about{
    margin-top: 7.5vh;
    min-height: 95vh;
}
.theCompany {
    display: flex;
}
.theCompanyLeft{
    width: 60%;
}
.theCompanyRight{
    width: 40%;
}
.theCompanyLeft *{
    margin-left: 10%;
    margin-right: 3%;
    margin-top: 3%;
}

.theCompanyRight img{
    height: 80%;
    width: 80%;
    margin-left: 10%;
    margin-top: 10%;
}
.ourJourney h3{
    margin-left: 6%;
    margin-top: 1%;
    margin-bottom: 1%;
}
.ourJourney ul{
    margin-left: 6%;
}

@media only screen and (max-width: 850px) {
    .theCompanyRight{
        display: none;
    }
    .theCompanyLeft{
        flex-grow: 0.95;
    }
}
