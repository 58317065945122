.bookTicketPage{
    margin-top: 7.5vh;
}
.bookTicketTitle{
    height: 7vh;
    width: 100%;
    background-color: var(--cream-color);
    font-size: xx-large;
    position: sticky;
    top: 7.5vh;
    z-index: 5;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.showDetailSelectionContainer{
    display: flex;
    width: 100%;
    height: 78vh;
    flex-wrap: wrap;    
}
.showOptionsSelection{
    width: 25%;
    height: 100%;
}
.showOptionsSelection form{
    display: flex;
    flex-direction: column;
    width: 90%;
    font-size: larger;
    margin-left: 5%;
}
.showOptionsSelection form label{
    margin-top: 12px;
    margin-bottom: 5px;
}
.showOptionsSelection form select {
    height: 30px;
    width: 100%;
    cursor: pointer;
}
.showOptionsSelection form select option{
    height: 30px;
    width: 80%;
    cursor: pointer;
}
.seatSelection{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}
.seatSelection img{
    max-width: 100%;
    margin-top: 2%;
}
.seatSelection table{
    height: 50%;
    width: 80%;
    border-collapse: separate;
    border-spacing: 2.5px 8px;
    margin-top: 25px;
    table-layout: fixed;
}
.seatSelection table tr td{
    height: 15px;
    width: 15px;
    text-align: center;
    cursor: pointer;
}
.rowName{
    color: red;
    border-spacing: 8px;
}
.seatName{
    border-radius: 5px;
    font-size: small;
    border: 1px solid grey;
}
.selectedSeat{
    background-color: green;
}
.bookedSeat{
    background-color: red;
    pointer-events: none;
    cursor: not-allowed;
    color: red;
}


.bookingSummary{
    width: 20%;
    height: 100%;
    margin-left: auto;
    max-height: 800px;
    box-shadow: 0px 0px 5px grey;
}
.selectedShowOverview{
    height: 28%;
    display: flex;
}
.selectedShowOverview img{
    width: 25%;
    height: 80%;
    margin-left: 10px;
    align-self: center;
}
.OtherSummary{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 2px;
}
.OtherSummary p{
    font-weight: 450;
    font-size: small;
}
.selectedSeatsOverview{
    height: 23%;
    overflow: auto;
}

.bookingSummary * h4{
    color: grey;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: small;
}
.selectedSeatsList{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-left: 10px;
}
.selectedSeatsDisplay{
    background-color: var(--golden-color);
    padding: 1.4px;
    border: 1px solid black;
    border-radius: 3px;
}
.paymentDetailOverview{
    height: 20%;
}
.paymentDetailOverview p{
    margin-left: 10px;
}
.proceedClass{
    height: 10%;
}

.proceedClass p{
    color: green;
    font-size: x-large;
    margin-left: 10px;
}
.proceedClass button{
    width: 90%;
    height: 32px;
    margin-top: 9%;
    background-color: var(--golden-color);
    border: 0px;
    box-shadow: 0px 0px 5px black;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}
.ticketBookingResponse , .ticketBookedPrompt{
    z-index: 4;
    position: fixed;
    background-color: transparent;
    height: 85vh;
    width: 100%;
    top: 7.5vh;
    left: 0%;
    display: none;
    align-items: center;
    justify-content: center;
}
.confirmationPrompt , .finalStatusPrompt{
    height: 30%;
    width: 30%;
    border: 1px solid black;
    background-color: #454242;
    border-radius: 9px;
}
.finalStatusPrompt{
    width: 20%;
    height: 25%;
}
.confirmationPrompt p , .finalStatusPrompt p{
    font-size: larger;
    margin-top: 5%;
    margin-left: 10px;
    color:white;
}
.confirmationPrompt Button , .finalStatusPrompt Button{
    background-color: var(--golden-color);
    margin-left: 20%;
    margin-top: 10%;
    border: 0px;
    height: 35px;
    width: 65px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px black;
    cursor: pointer;
}
.finalStatusPrompt button{
    margin-left: 40%;
}
@media only screen and (max-width:1000px){
    .showOptionsSelection{
        height: 70%;
        width: 28%;
        margin-top: 10%;
    }
    .seatSelection{
        height: 80%;
        width: 70%;
    }
    .bookingSummary{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20%;
        font-size: small;
    }
    .selectedShowOverview{
        height: 100%;
        width: 35%;
    }
    .selectedSeatsOverview{
        height: 100%;
        min-width: 20%;
        max-width: 28%;
    }
    .paymentDetailOverview{
        height: 100%;
        width: 20%;
    }
    .confirmationPrompt , .finalStatusPrompt{
        height: 20%;
        width: 50%;
    }

}
@media only screen and (max-width:550px){
    .showDetailSelectionContainer{
        height: auto;
    }
    .showOptionsSelection{
        height: 75%;
        width: 100%;
        margin-top: 0%;
    }
    .seatSelection{
        height: 100%;
        width: 100%;
    }
    .seatSelection table{
        height: 50%;
        width: 80%;
        border-collapse: separate;
        border-spacing: 2.8px 6.5px;
        margin-top: 25px;
        table-layout: fixed;
    }
    .seatSelection table tr td{
        height: 11.3px;
        width: 11.3px;
        border-radius: 2px;
        font-size: xx-small;
    }
    .confirmationPrompt , .finalStatusPrompt{
        height: 25%;
        width: 80%;
    }
}