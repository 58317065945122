@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
* {
    margin: 0px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 500;
}

:root {
    --golden-color: #ffd903;
    --grey-color: #f1f1f1;
    --cream-color: #fff0d6;
    --grey-subtle: #f8f9fa;
    --xx-small: 9px;
    --x-small: 10px;
    --small: 12px;
    --medium: 16px;
    --large: 18px;
    --x-large: 24px;
    --xx-large: 36px;
  }