.headerContainer{
    display: flex;
    height: 7.5vh;
    width: 100vw;
    flex-direction: row;
    position: fixed;
    background-color: #fbf8f8;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 2px solid black;
}
.logo{
    height: 100%;
    width: 20%;
    min-width: 240px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}
.logo img{
    height: 100%;
    width: 30%;
}
.logo p{
    font-size: xx-large;
    font-style: italic;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: var(--golden-color);
    text-emphasis: bolder;
}

.navbar{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.navOption svg{
    vertical-align: middle;
}

.navOption span{
    vertical-align: bottom;
    font-size: large;
}

.navOption a {
    text-decoration: none;
    color: black;
}
.activePage{
    background-color: var(--golden-color);
    padding: 8px 8px 8px 8px;
    border-radius: 20px;
}
.navOption a:hover{
    opacity: 0.66;
}

.navRight{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-width: 25%;
    justify-content: space-evenly;
    align-items: center;
    gap: 6px;
}

.navRight * Button {
    border: 1px solid black;
    text-transform: none;
    color: black;
}
.navRight .searchIcon Button {
    border-radius: 0px;
}
.navRight .Location Button {
    background-color: var(--grey-color);
}

.navRight .profile Button {
    background-color: var(--cream-color);
}
.searchBox{
    display: flex;
    width: 230px;
}
.searchIcon{
    display: flex;
}
.searchResults{
    display: flex;
    height: auto;
    width: 300px;
    background-color: #fbf8f8;;
    position: fixed;
    z-index: 3;
    top: 6.5vh;
    max-height: 50%;
    overflow: auto;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}
.searchResults ul{
    list-style: none;
    padding: 2px;
}
.searchResults ul li{
    cursor: pointer;
    height: 30px;
    padding-left: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
}
.searchResults ul li:hover{
    background-color: grey;
}
.smallScreens{
    display: none;
}
.menuItemsList{
    display: flex;
    flex-direction: column;
    margin-top: 1.2vh;
    background-color: var(--grey-color);
    width: 150px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px black;
}
.menuItem svg{
    vertical-align: bottom;
}
.menuItem {
    color: black;
    text-decoration: none;
    padding: 8px;
    border-bottom: 1px solid black;
}
.menuItem:first-child{
    background-color: rgb(255, 0, 0);
}
.menuItem:first-child:hover{    
    background-color: rgba(255, 0, 0, 0.649);
}
.menuItem:hover{
    color:white;
    background-color: black;
}

.closeButton svg{
   color: white;
}


@media only screen and (max-width: 1020px) {
    .navbar{
        display: none;
    }
    .smallScreens{
        display: block;
    }
    .navRight{
        width: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 650px){
    .Location , .profile{
        display: none;
    }
    .navRight{
        min-width: 55px;
        margin-left: auto;
        justify-content: left;
    }
    .logo{
       margin-left: 0px;
       width: 190px;
       gap: 0px;
       justify-content: left;
       min-width: 190px;
    }
    .logo img{
        width: 60px;
    }
    .logo p{
        font-size: x-large;
    }
    .searchBox{
        display: flex;
        width: 100px;
    }
    .searchIcon Button{
        display: block;
        width: 4px;
        padding: 0px;
    }
    .searchResults{
        width: 170px;
    }

}


