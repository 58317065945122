.shows{
    margin-top: 7.5vh;
}
.showsTitle{
    height: 7vh;
    width: 100%;
    background-color: var(--cream-color);
    font-size: xx-large;
    position: sticky;
    top: 7.5vh;
    z-index: 5;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.showsTitle p{
    margin-left: 10%;
}

.showDateContainer{
    list-style: none;
    display: flex;
    height: 9vh;
    background-color: white;
    width: 100%;
    justify-content: center;
    padding: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.393);
    position: sticky;
    top: 14.5vh;
    z-index: 5;
}
.showDateContainer li{
    width: 7%;
}
.dateButtons{
    display: flex;
    cursor: pointer;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.active{
    background-color: var(--cream-color);
    border-bottom: 2px solid var(--golden-color);
}

.dateText{
    font-size: xx-large;
}
.monthText{
    font-size: medium;
}

.showsContainer{
    width: 100%;
    background-color: var(--grey-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 63vh;
}
.movieShow{
    width: 75%;
    border-radius: 7px;
    min-height: 9vh;
    margin-top: 18px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.movieShow:last-child{
    margin-bottom: 25px;
}

.movieShowLeft{
    width: 60%;
    min-height: 9vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.movieShowLeft p{
    padding-left: 5%;
}
.movieShowLeft p:first-of-type{
    font-weight: 800;
    font-size: x-large;
}
.movieShowRight{
    margin-left: auto;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}
.movieShowRight * svg{
    cursor: pointer;
}
.showDetailedView{
    width: 100%;
    height: auto;
    display: none;
}
.cinemaAndShowContainer{
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.cinemaAndShowContainer hr{
    width: 92%;
    align-self: center;
}
.cinemaAndShowContainer p{
    margin-left: 5%;
    font-weight: 600;
    font-size: larger;
}

.cinemaAndShowContainer button{
    height: 40px;
    margin-left: 5%;
    width: 17%;
    cursor: pointer;
    background-color: rgba(50, 214, 50, 0.182);
    color: green;
    font-size: medium;
    font-weight: 700;
    border: 1px solid green;
    border-radius: 5px;
}
@media only screen and (max-width:650px){
    .showDateContainer{
        overflow: hidden;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .showDateContainer li{
        width: 9.5%;
        margin-left: 5%;
    }
    .movieShow{
        width: 95%;
    }
    .cinemaAndShowContainer button{
        width: 24%;
    }
    .showTimingButtons button{
        white-space: nowrap;
    }
    .movieShowLeft p:first-of-type{
        font-weight: 600;
    }
    .movieShowLeft *{
        font-weight: 400;
        font-size: small;
    }

    .movieShowRight p{
        font-weight: 500;
    }
}