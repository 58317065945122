.signUp{
    margin-top: 7.5vh;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(black, grey, white);
}
.signUp form{
    background-color: white;
    width: 25%;
    height: 70%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px black;
    border-radius: 3%;
}
.signUp form *{
    margin-left: 15%;
}
.signUp form p a{
    margin-left: 1%;
}
.signUp form h1{
    margin-bottom: 3%;
    align-self: center;
    margin-left: 0px;
}
.signUp form label{
    margin-top: 3%;
}

.signUp form input{
    padding: 0px;
    height: 8%;
    width: 70%;
    border: 0px;
    border-bottom: 2px solid black;
    font-style: italic;
}
.signUp form input:focus{
    border: 0px;
    border-bottom: 2px solid black;
    outline: none;
}
.submitButton{
    margin-top: 15px;
    font-style: normal;
    cursor: pointer; 
    height: 8%;
    width: 70%;
    background-color: rgba(50, 214, 50, 0.381);
    color: green;
    box-shadow: 0px 0px 3px green;
    border: 0px;
    border-radius: 5px;
}