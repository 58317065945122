.comingSoonMovieDetailPage{
    min-height: 85vh;
    width: 100%;
    margin-top: 7.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-color);
}
.comingSoonMovieContainer{
    box-shadow: 0px 0px 10px black;
    padding : 2% 1.5% 2%;
    margin: 10px 0px 10px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 90%;
    max-width: 75%;
}

.person::before{
    content: '+';
}
.person:last-child{
    margin-bottom: 20px;
}

@media only screen and (max-width:700px){
    .comingSoonMovieContainer{
        flex-direction: column;
        max-width: 90%;
    }
}