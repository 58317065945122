.Login{
    margin-top: 7.5vh;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(black, grey, white);
}
.Login form{
    padding-top:25px;
    background-color: white;
    width: 25%;
    height: 50%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px black;
    border-radius: 3%;
}
.Login form *{
    margin-left: 15%;
}
.Login form p a{
    margin-left: 1%;
}
.Login form h1{
    margin-bottom: 3%;
    align-self: center;
    margin-left: 0px;
}
.Login form label{
    margin-top: 3%;
}

.Login form input{
    padding: 0px;
    height: 8%;
    width: 70%;
    border: 0px;
    border-bottom: 2px solid black;
    font-style: italic;
}
.Login form input:focus{
    border: 0px;
    border-bottom: 2px solid black;
    outline: none;
}
.submitButton{
    margin-top: 40px;
    margin-bottom: 20px;
    font-style: normal;
    cursor: pointer; 
    height: 8%;
    width: 70%;
    background-color: rgba(50, 214, 50, 0.381);
    color: green;
    box-shadow: 0px 0px 3px green;
    border: 0px;
    border-radius: 5px;
    margin: 12px;
}