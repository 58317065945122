.cinemas{
    height: auto;
    margin-top: 7.5vh;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}
.cinemasTitle{
    height: 7vh;
    width: 100%;
    background-color: var(--cream-color);
    font-size: xx-large;
    position: sticky;
    top: 7.5vh;
    z-index: 5;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.cinemasTitle p{
    margin-left: 10%;
}
.cinemaContainer{
    margin-top: 20px;
    height: 30vh;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.553);
    display: flex;
    width: 90%;
    margin-bottom: 1%;
    border-radius: 15px;
}
.containerLeft{
    width: 25%;
    height: 100%;
}
.containerMiddle{
    width: 25%;
    height: 100%;
    margin-top: 3%;
}
.containerRight{
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.moviePosters{
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap: 10px;
    margin-top: 2%;
}
.moviePosters img{
    height: 100%;
    width: 25%;
}
.containerRight h5{
    margin-left: auto;
}
.containerLeft img{
    height: 75%;
    width: 75%;
    margin-top: 10%;
    margin-left: 10%;
    border-radius: 5px;
}
.containerMiddle p{
    margin-top: 5px;
}
.containerMiddle p svg{
    font-size: large;
    vertical-align: bottom;
}
@media only screen and (max-width:1024px){

}
@media only screen and (max-width: 770px){
    .cinemaContainer{
        height: 60vh;
        display: flex;
        width: 90%;
        flex-wrap: wrap;
    }
    .containerLeft{
        width: 60%;
        height: 48%;
    }
    .containerMiddle{
        width: 40%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .containerRight{
        width: 95%;
        height: 50%;
    }
    .containerRight h3{
        padding-left: 15px;
    }
    .moviePosters{
        height: 80%;
        width: 100%;
        gap: 10px;
        margin-top: 2%;
        margin-left: 3%;
        overflow: hidden;
    }
    .moviePosters img{
        height: 100%;
    }
}
@media only screen and (max-width: 520px){
    
}